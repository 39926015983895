import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from "react-bootstrap/Jumbotron"
import Card from "react-bootstrap/Card"

export const data = graphql`
  query {
    allWebinarDataset {
      edges {
        node {
          id
          FeaturedImageURL
          AuthorPic
          AuthorName
          AboutAuthor
          LongDescription
          ShortDescription
          Title
          Id
          SlugURL
        }
      }
    }
  }
`

export const seo = {
  title: "Webinars | Crampete",
  description:
    "Register for upcoming Crampete webinars on career changing technology topics like full-stack web development, data science and job placement success. Recordings available.",
  keywords: "Crampete, Webinars",
  image: "",
  url: "https://www.crampete.com/webinars",
  author: "Crampete",
}

const Webinar = ({ data }) => {
  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={seo.image}
      keywords={seo.keywords}
      url={seo.url}
      author={seo.author}
    >
      <Jumbotron className="bg-lightblue">
        <div class="container">
          <div
            style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}
          >
            <h2>Webinars</h2>
            <p className="mt-4">
              Attend upcoming career-focused webinars, learn something new and
              interact with a master of the topic. Our webinars are offered
              freely.
            </p>
          </div>
        </div>
      </Jumbotron>
      <div class="container">
        <div className="row">
          {data.allWebinarDataset.edges.map(({ node }) => {
            return (
              <div
                class="col-md-6 col-lg-4 col-xl-4"
                style={{ marginBottom: "20px" }}
              >
                <Card className="cardShadow">
                  <Card.Img variant="top" src={node.FeaturedImageURL} />
                  <Card.Body>
                    <Card.Title>{node.Title}</Card.Title>
                    <Card.Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.ShortDescription,
                        }}
                      />
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Link
                      className="btn btn-primary"
                      to={"/webinar/" + node.SlugURL}
                    >
                      Read more
                    </Link>
                  </Card.Body>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Webinar
